import { createBrowserRouter, RouteObject } from 'react-router-dom'
import { RouteGuard } from '../components/RouteGuard'
import { ALARM_REVIEW_APPLET_SCOPES, ALARM_SMS_REVIEW_APPLET_SCOPES, PERMITTING_APPLET_SCOPES } from '../constants'
import { AppLayout } from '../layouts/AppLayout'
import { FeatureViewerLayout } from '../layouts/FeatureViewerLayout'
import { PermitLayout } from '../layouts/PermitLayout'
import { AlarmSmsPage } from './AlarmSmsPage'
import { AlarmsPage } from './AlarmsPage'
import { FeatureViewerPage } from './FeatureViewerPage'
import { JurisdictionDetailsPage } from './JurisdictionDetailsPage'
import { LandingPage } from './LandingPage'
import { NotFoundPage } from './NotFoundPage'
import { PermitPage } from './PermitPage'
import { UnauthorizedPage } from './UnauthorizedPage'
import { AlarmSmsLayout } from '../layouts/AlarmSmsLayout'
import { addressSchedulerRoutes } from './addressScheduler/addressScheduler.routes.tsx'

/**
 * Note: Top-level components need to render a Page Component
 */
export const routes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      { path: '/', element: <LandingPage /> },
      {
        element: <RouteGuard element={<AlarmSmsLayout />} has={ALARM_SMS_REVIEW_APPLET_SCOPES} mode={'all'} />,
        children: [
          { path: 'alarm-sms-review/:sid?', element: <AlarmsPage /> },
          { path: 'alarm-sms-review/:sid/:alarmId?', element: <AlarmSmsPage /> }
        ]
      },
      {
        element: <RouteGuard element={<FeatureViewerLayout />} has={ALARM_REVIEW_APPLET_SCOPES} mode={'all'} />,
        children: [{ path: 'location-features/:sid?', element: <FeatureViewerPage /> }]
      },
      {
        element: <RouteGuard element={<PermitLayout />} has={PERMITTING_APPLET_SCOPES} mode={'all'} />,
        children: [
          { path: 'permits/:sid?', element: <PermitPage /> },
          { path: 'jurisdiction-details/:sid?', element: <JurisdictionDetailsPage /> }
        ]
      },
      {
        path: 'address-scheduler',
        children: addressSchedulerRoutes
      },
      { path: 'unauthorized', element: <UnauthorizedPage /> },
      { path: '*', element: <NotFoundPage /> }
    ]
  }
]

export const appRouter = createBrowserRouter(routes)
