import { RouteObject } from 'react-router-dom'
import { NotFoundPage } from '../NotFoundPage'
import { OverviewPage } from './OverviewPage/OverviewPage.tsx'
import { SearchPage } from './SearchPage/SearchPage.tsx'

/**
 * Note: Top-level components need to render a Page Component
 */
export const addressSchedulerRoutes: RouteObject[] = [
  { path: '', element: <SearchPage /> },
  {
    path: ':sid',
    children: [
      { path: 'overview', element: <OverviewPage /> },
      { path: 'address-form', element: <NotFoundPage /> },
      { path: 'validation-results', element: <NotFoundPage /> },
      { path: 'confirmation', element: <NotFoundPage /> },
      { path: 'time-zone', element: <NotFoundPage /> },
      { path: 'date-picker', element: <NotFoundPage /> }
    ]
  }
]
