import { Card, Stack, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { components } from '../../api/generated/museum-v1-types.ts'

interface FeatureCardProps {
  feature: components['schemas']['LocationFeature']
}

const PanelItem: FC<{ label: ReactNode; value: ReactNode }> = ({ label, value }) => {
  return (
    <Stack>
      <Typography fontSize={'1rem'} fontWeight={500}>
        {label}
      </Typography>
      <Typography fontSize={'1.5rem'} fontWeight={300}>
        {value}
      </Typography>
    </Stack>
  )
}

const FeatureConsent: FC<{ value: components['schemas']['LocationFeatureConsent'] }> = ({ value }) => {
  const humanDate = new Date(value.acceptedAt!).toLocaleString()
  return (
    <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'} flexGrow={1} gap={'1rem'} useFlexGap>
      <PanelItem label={'Consented By'} value={value.acceptedBy} />
      <PanelItem label={'Consent Timestamp'} value={humanDate} />
    </Stack>
  )
}

export const FeatureCard: FC<FeatureCardProps> = ({ feature }) => {
  return (
    <Card variant="outlined" sx={{ padding: '1rem' }}>
      <Stack direction={'column'} alignItems={'start'} justifyContent={'space-between'} flexGrow={1} gap={'1rem'} useFlexGap>
        <Stack direction={'column'} alignItems={'start'} justifyContent={'space-between'} flexGrow={1} gap={'1rem'} useFlexGap marginRight={'1rem'}>
          <PanelItem label={'Name'} value={feature.name} />
          <PanelItem label={'Enabled'} value={feature.enabled ? 'Yes' : 'No'} />
        </Stack>
        {feature.consent && feature.consent.acceptedBy && feature.consent.acceptedAt && <FeatureConsent value={feature.consent!} />}
      </Stack>
    </Card>
  )
}
