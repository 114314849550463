import { createQueryKeyStore } from '@lukemorales/query-key-factory'

// if you prefer to declare everything in one file
export const keyStore = createQueryKeyStore({
  locations: {
    byId: (sid: string) => [sid],
    getSid: (systemNumber: string) => [systemNumber],
    changes: (sid: string) => [sid]
  },
  permits: {
    notes: (sid: string) => [sid],
    history: (sid: string) => [sid]
  },
  locationAlarms: {
    all: (sid: string) => [sid],
    byId: (sid: string, alarmId: string) => [sid, alarmId],
    smsIncident: (sid: string, alarmId: string) => [sid, alarmId]
  }
})
