export * from './AgencyPermitRequirement.ts'
export * from './PermitSource'
export * from './ResidenceType'
export * from './SimplisafeTimeZoneName.ts'

/**
 * The default GC Time for the query client, expressed in milliseconds.
 */
export const DEFAULT_GC_TIME = 30 * 60 * 1000

/**
 * The default stale time for the query client, expressed in milliseconds.
 */
export const DEFAULT_STALE_TIME = 15 * 60 * 1000

/**
 * The links that can be accessed through the location info box.
 */
export const LOCATION_INFO_LINKS = {
  CSR_PAGE: (uid: string, env: string) => {
    return `https://${env}simplisafe.com/admin/customer-service/csr/${uid}`
  },
  SERVICE_PAGE: (sid: string, env: string) => {
    return `https://${env}simplisafe.com/admin/store/service/subscription/edit/${sid}`
  }
} as const

/**
 * The links that can be accessed through the permit location component.
 */
export const PERMIT_LOCATION_INFO_LINKS = {
  CRM_PAGE: (uid: string, sid: string, env: string) => {
    return `https://${env}simplisafe.com/admin/eds/location/${uid}/${sid}`
  }
} as const

/**
 * The openid scope.
 */
const OPEN_ID_SCOPE = 'openid'

/**
 * The openid scope.
 */
const PROFILE_SCOPE = 'profile'

/**
 * The openid scope.
 */
const EMAIL_SCOPE = 'email'

/**
 * The scope for getting access to the interim version of the Permit Management App. This should be deleted once the legacy version of the app is turned off.
 */
export const PREVIEW_SCOPE = 'https://art.mon.simplisafe.com/features:preview'

/**
 * The scope for getting location data from Museum.
 */
export const READ_LOCATIONS_SCOPE = 'https://museum.mon.simplisafe.com/locations:read'

/**
 * The scope for getting alarm data from Museum.
 */
export const READ_ALARMS_SCOPE = 'https://museum.mon.simplisafe.com/locations:alarms:read'

/**
 * The scope for posting metrics from Museum.
 */
export const WRITE_METRICS_SCOPE = 'https://museum.mon.simplisafe.com/metrics:write'

/**
 * The scope for getting MSP location data from Museum.
 */
export const READ_MSP_LOCATIONS_SCOPE = 'https://museum.mon.simplisafe.com/monitoring-service-providers:locations:read'

/**
 * The scope for getting alarm recording data from Museum.
 */
export const READ_ALARM_RECORDINGS_SCOPE = 'https://api.monitoring.simplisafe.com/locations:alarms:recordings:read'

/**
 * The scope for getting alarm SMS incidents data from Partyline.
 */
export const READ_ALARM_SMS_INCIDENTS_SCOPE = 'https://partyline.mon.simplisafe.com/read:incidents'

/**
 * The scope for writing locations data to Museum.
 */
export const WRITE_LOCATIONS_SCOPE = 'https://museum.mon.simplisafe.com/locations:write'

/**
 * The scope for getting permitting comments data from Museum.
 */
export const READ_PERMITTING_COMMENTS_SCOPE = 'https://museum.mon.simplisafe.com/read:locations:permitting-comments'

/**
 * The scope for writing permitting comments to Museum.
 */
export const WRITE_PERMITTING_COMMENTS_SCOPE = 'https://museum.mon.simplisafe.com/write:locations:permitting-comments'

/**
 * The scope for reading location changes.
 */
export const READ_LOCATION_CHANGE_SCOPE = 'read:location-change'
export const ALL_LOCATION_CHANGE_SCOPES = [READ_LOCATION_CHANGE_SCOPE]

// Alarm Review scopes
export const READ_ALARM_REVIEW_SCOPES = [READ_LOCATIONS_SCOPE, READ_ALARMS_SCOPE, READ_ALARM_RECORDINGS_SCOPE]
export const WRITE_ALARM_REVIEW_SCOPES = [WRITE_LOCATIONS_SCOPE, WRITE_LOCATIONS_SCOPE, WRITE_METRICS_SCOPE]
export const ALL_ALARM_REVIEW_SCOPES = [...READ_ALARM_REVIEW_SCOPES, ...WRITE_ALARM_REVIEW_SCOPES, READ_MSP_LOCATIONS_SCOPE]

// Alarm SMS Review scopes
export const READ_ALARM_SMS_REVIEW_SCOPES = [READ_LOCATIONS_SCOPE, READ_ALARMS_SCOPE, READ_ALARM_SMS_INCIDENTS_SCOPE]

// Permits scopes
export const READ_PERMITTING_SCOPES = [READ_LOCATIONS_SCOPE, READ_PERMITTING_COMMENTS_SCOPE]
export const WRITE_PERMITTING_SCOPES = [WRITE_LOCATIONS_SCOPE, WRITE_PERMITTING_COMMENTS_SCOPE]
export const ALL_PERMITTING_SCOPES = [...READ_PERMITTING_SCOPES, ...WRITE_PERMITTING_SCOPES, READ_MSP_LOCATIONS_SCOPE]

// Landing Page Applet Visibility and Top Level Routing
export const ALARM_SMS_REVIEW_APPLET_SCOPES = READ_ALARM_SMS_REVIEW_SCOPES
export const ALARM_REVIEW_APPLET_SCOPES = [READ_LOCATIONS_SCOPE, READ_ALARMS_SCOPE]
export const PERMITTING_APPLET_SCOPES = [PREVIEW_SCOPE, READ_LOCATIONS_SCOPE]
export const LOCATION_FEATURES_APPLET_SCOPES = [READ_LOCATIONS_SCOPE]
export const ADDRESS_SCHEDULER_APPLET_SCOPES = [READ_LOCATION_CHANGE_SCOPE]

export const PROFILE_SCOPES = [OPEN_ID_SCOPE, PROFILE_SCOPE, EMAIL_SCOPE] as const

export const ALL_SCOPES = [
  ...ALL_ALARM_REVIEW_SCOPES,
  ...ALL_PERMITTING_SCOPES,
  ...ALL_LOCATION_CHANGE_SCOPES,
  ...PROFILE_SCOPES,
  WRITE_METRICS_SCOPE,
  READ_ALARM_SMS_INCIDENTS_SCOPE,
  PREVIEW_SCOPE
]

/**
 * The properties needed to authenticate in the Auth0 service.
 */
export const AUTH_PROPERTIES = {
  /**
   * The audience needed for authentication.
   */
  MUSEUM_AUDIENCE: 'https://api.monitoring.simplisafe.com/'
} as const

/**
 * The sidebar name for the Permits page.
 */
export const SIDEBAR_PERMITS = 'Permits'

/**
 * The sidebar name for the Jurisdiction Details page.
 */
export const SIDEBAR_JURISDICTION_DETAILS = 'Jurisdiction Details'

/**
 * The names of the pages for the sidebar component.
 */
export const SIDEBAR_PAGE_NAMES = [SIDEBAR_PERMITS, SIDEBAR_JURISDICTION_DETAILS]
