import { FC } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLocation, useLocationChanges } from '../../../api/apiQueries.ts'
import { Box, Stack } from '@mui/material'
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen.tsx'
import { Page } from '../../../components/Page'
import { LocationCard } from '@simplisafe/blueprint-web-dsl'
import { getCrmUrl, getCsrPageUrl, getServicePageUrl, getResidenceType } from '../../../utils/permitUtils.ts'
import { Skeleton } from '../../../components/Skeleton/Skeleton.tsx'
import { OverviewPreview } from './OverviewPreview.tsx'
import { AddressChangesList } from './AddressChangesList.tsx'

const OverviewPage: FC = () => {
  const { sid } = useParams()
  const locationQuery = useLocation(sid)
  const location = locationQuery.data
  const navigate = useNavigate()
  const locationChanges = useLocationChanges(sid)

  const handleScheduleAddress = () => {
    navigate(`../address-form`)
  }

  const handleScheduleEdit = () => {
    console.log('Schedule edit clicked')
  }

  if (locationQuery.isError) {
    return (
      <Page>
        <ErrorScreen
          data-testid="error-screen"
          title="Oops, sorry this SID or System Number could not be found!"
          message={'A refresh may fix this issue.'}
          flexGrow={1}
        />
      </Page>
    )
  }

  return (
    <Page title={'Address Change Scheduler'} sx={{ padding: 0 }}>
      <Stack direction={'row'} spacing={'var(--spacing-s)'}>
        <Box
          sx={{
            padding: 'var(--spacing-l)'
          }}
        >
          <Link
            to="../../"
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap'
            }}
          >
            {'< Back'}
          </Link>
        </Box>

        <Stack
          flexGrow={1}
          spacing={'2rem'}
          useFlexGap
          data-testid="address-scheduler-content"
          sx={{
            padding: 'var(--spacing-l)'
          }}
        >
          <Skeleton isLoading={locationQuery.isLoading} variant={'rounded'}>
            {location && (
              <LocationCard
                location={location}
                crmUrl={getCrmUrl(location)}
                csrUrl={getCsrPageUrl(location)}
                servicePageUrl={getServicePageUrl(location)}
                residenceType={getResidenceType(location)}
              />
            )}
          </Skeleton>

          <OverviewPreview onScheduleNewAddress={handleScheduleAddress} />

          {locationChanges.data && <AddressChangesList onSchedulerEdit={handleScheduleEdit} addressChanges={locationChanges.data} />}
        </Stack>
      </Stack>
    </Page>
  )
}

export { OverviewPage }
