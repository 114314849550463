import { Grid2 as Grid, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import AddressSchedulerIcon from '../../assets/images/address-scheduler-icon.svg?react'
import IntrusionAlarmReviewIcon from '../../assets/images/intrusion-alarm-review-icon.svg?react'
import PermitManagementIcon from '../../assets/images/permit-management-icon.svg?react'
import { AuthCheck } from '../../components/AuthCheck'
import { Page } from '../../components/Page'
import {
  ADDRESS_SCHEDULER_APPLET_SCOPES,
  ALARM_SMS_REVIEW_APPLET_SCOPES,
  LOCATION_FEATURES_APPLET_SCOPES,
  PERMITTING_APPLET_SCOPES
} from '../../constants'
import { AppCard } from './AppCard.tsx'

export const LandingPage: FC = () => {
  return (
    <Page title={'Admin Review Tool'} sx={{ padding: '4rem' }} data-testid={'landing-page'}>
      <Stack spacing={'2rem'}>
        <Typography sx={{ fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase' }}>Apps</Typography>
        <Grid container spacing={'var(--spacing-l)'}>
          {/*
          <AuthCheck has={ALARM_REVIEW_APPLET_SCOPES}>
            <Grid>
              <AppCard
                name={'Intrusion Alarm Review'}
                description={'QA Intrusion Alarm Incidents'}
                icon={<IntrusionAlarmReviewIcon />}
                color={'var(--yellow-500)'}
                path={'/intrusion-alarm-review'}
                data-testid={'intrusion-alarm-review'}
              />
            </Grid>
          </AuthCheck>
          */}
          <AuthCheck has={PERMITTING_APPLET_SCOPES}>
            <Grid>
              <AppCard
                name={'Permit Management'}
                description={'View and Update Permits'}
                icon={<PermitManagementIcon />}
                color={'var(--green-light-800)'}
                path={'/permits'}
                data-testid={'permit-management-app'}
              />
            </Grid>
          </AuthCheck>
          <AuthCheck has={ALARM_SMS_REVIEW_APPLET_SCOPES}>
            <Grid>
              <AppCard
                name={'Alarm SMS Review'}
                description={'Review Customer SMS for Alarm Incidents'}
                icon={<IntrusionAlarmReviewIcon />} // TODO: Replace with Alarm SMS icon
                color={'var(--red-600)'}
                path={'/alarm-sms-review'}
                data-testid={'alarm-sms-review-app'}
              />
            </Grid>
          </AuthCheck>
          <AuthCheck has={LOCATION_FEATURES_APPLET_SCOPES}>
            <Grid>
              <AppCard
                name={'Location Feature Viewer'}
                description={'Show which features are enabled at a location'}
                icon={<IntrusionAlarmReviewIcon />} // TODO: Replace with a newer icon
                color={'var(--blue-light-700)'}
                path={'/location-features'}
                data-testid={'location-features-app'}
              />
            </Grid>
          </AuthCheck>
          <AuthCheck has={ADDRESS_SCHEDULER_APPLET_SCOPES}>
            <Grid>
              <AppCard
                name={'Address Scheduler'}
                description={'Schedule a Customers Move'}
                icon={<AddressSchedulerIcon />}
                color={'var(--yellow-500)'}
                path={'/address-scheduler'}
                data-testid={'address-scheduler-app'}
              />
            </Grid>
          </AuthCheck>
        </Grid>
      </Stack>
    </Page>
  )
}
