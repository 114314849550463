import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { CreatePermitRequest, Location, LocationPermit } from '../../api'
import { useCreatePermitByAgencyType, useDeletePermitByAgencyType, useUpdatePermitByAgencyType } from '../../api/apiQueries'
import { DateTime } from 'luxon'
import { fromDatePicker, toDatePicker } from '../../utils/dateUtils.ts'
import {
  displayAgencyPermitRequirement,
  getStatusActionConfirmation,
  getStatusActionPrompt,
  hasStatusAction,
  isMissingPermit,
  permitDoesNotExpire
} from '../../utils/permitUtils.ts'
import { AgencyTypeValues } from '../../constants/AgencyType.ts'
import { useSnackbar } from '../../hooks/useSnackbar.tsx'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { AppFormLink } from './AppFormLink.tsx'
import { PermitExpiredCellContents } from './PermitExpiredCellContents.tsx'
import LoadingButton from '@mui/lab/LoadingButton'

type PermitEditorProps = {
  location: Location
  permit?: LocationPermit
  agencyType: AgencyTypeValues
  onCancel: () => void
  onOperationSuccess: () => void
}

type PermitFormData = {
  permitNumber: string
  expirationDate: DateTime | null
  noNumberGiven: boolean
  noExpirationGiven: boolean
  permitNoLongerNeeded: boolean
  clearStatusAction: boolean
}

const PermitRowEditor: FC<PermitEditorProps> = ({ location, agencyType, permit, onCancel, onOperationSuccess }) => {
  const createPermitByAgencyTypeMutation = useCreatePermitByAgencyType()
  const updatePermitByAgencyTypeMutation = useUpdatePermitByAgencyType()
  const deletePermitByAgencyTypeMutation = useDeletePermitByAgencyType()
  const { toast } = useSnackbar()

  const [originalPermitNumber, setOriginalPermitNumber] = useState(permit?.permitNumber || '')
  const [originalExpirationDate, setOriginalExpirationDate] = useState<DateTime | null>(toDatePicker(permit?.expirationDate))

  const [formValues, setFormValues] = useState<PermitFormData>({
    permitNumber: permit?.permitNumber || '',
    expirationDate: toDatePicker(permit?.expirationDate),
    noNumberGiven: permit ? !permit?.permitNumber : false,
    noExpirationGiven: permit ? !permit?.expirationDate : false,
    permitNoLongerNeeded: false,
    clearStatusAction: false
  })

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm<PermitFormData>({
    values: formValues
  })

  const updateFormValue = (field: keyof PermitFormData, value: unknown) => {
    setFormValues(prev => ({ ...prev, [field]: value }))
  }

  useEffect(() => {
    reset(formValues)
  }, [formValues, reset])

  const noNumberGiven = watch('noNumberGiven')
  const noExpirationGiven = watch('noExpirationGiven')

  useEffect(() => {
    if (noNumberGiven) {
      setValue('permitNumber', '')
    } else {
      setValue('permitNumber', originalPermitNumber)
    }
    if (noExpirationGiven) {
      setValue('expirationDate', null)
    } else {
      setValue('expirationDate', originalExpirationDate)
    }
  }, [noNumberGiven, noExpirationGiven, setValue, originalPermitNumber, originalExpirationDate])

  const onSubmit = async (data: PermitFormData) => {
    try {
      if (data.permitNoLongerNeeded) {
        if (permit) {
          await deletePermitByAgencyTypeMutation.mutateAsync({
            locationId: location.id,
            agencyType
          })
          toast('Permit deleted.', { variant: 'success' })
        }
      } else {
        const request: CreatePermitRequest = {
          source: 'SimpliSafe',
          isNotNumbered: data.noNumberGiven,
          isEternal: data.noExpirationGiven,
          permitNumber: data.noNumberGiven ? undefined : data.permitNumber,
          expirationDate: fromDatePicker(data.expirationDate)
        }

        if (permit) {
          await updatePermitByAgencyTypeMutation.mutateAsync({
            locationId: location.id,
            agencyType,
            request
          })
          toast('Changes saved.', { variant: 'success' })
        } else {
          await createPermitByAgencyTypeMutation.mutateAsync({
            locationId: location.id,
            agencyType,
            request
          })
          toast('Permit saved.', { variant: 'success' })
        }
      }
      reset()
    } finally {
      onOperationSuccess()
    }
  }

  return (
    <>
      {hasStatusAction(location, agencyType) && (
        <TableRow data-testid={`${agencyType}-permit-row-edit-prompt`}>
          <TableCell colSpan={7} sx={{ backgroundColor: 'var(--bg-alert)', p: '1rem 1.5rem', border: 'none' }}>
            <Stack spacing={'1rem'}>
              <Stack direction="row" spacing={'.5rem'} alignItems="center" useFlexGap sx={{ color: 'var(--text-error)' }}>
                <ErrorOutlineIcon />
                {getStatusActionPrompt(location, agencyType)}
              </Stack>
              {!isMissingPermit(location, agencyType) && (
                <Controller
                  name="clearStatusAction"
                  control={control}
                  rules={{ required: 'Please confirm that you have verified the information below' }}
                  render={({ field, fieldState }) => (
                    <FormControl error={fieldState.invalid}>
                      <Box sx={{ marginLeft: '1.5rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              data-testid={`${agencyType}-verified-checkbox`}
                              {...field}
                              checked={field.value}
                              onChange={e => {
                                field.onChange(e.target.checked)
                                updateFormValue('clearStatusAction', e.target.checked)
                              }}
                            />
                          }
                          label={<Typography>{getStatusActionConfirmation(location, agencyType)}</Typography>}
                        />
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      </Box>
                    </FormControl>
                  )}
                />
              )}
            </Stack>
          </TableCell>
        </TableRow>
      )}

      <TableRow
        className={'selected-indicator'}
        sx={{ '&>td': { borderBottom: 'none', paddingBottom: 0 } }}
        data-testid={`${agencyType}-permit-row-editor`}
      >
        <TableCell>
          <PermitExpiredCellContents agencyType={agencyType} location={location} />
        </TableCell>
        <TableCell>{agencyType.charAt(0).toUpperCase() + agencyType.slice(1)}</TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none', verticalAlign: 'top' }}>
          <TextField
            {...register('permitNumber', {
              required: !noNumberGiven ? 'Permit number is required' : false,
              maxLength: {
                value: 50,
                message: 'Permit number cannot exceed 50 characters'
              },
              validate: value => noNumberGiven || value.trim() !== '' || 'Please provide a permit number',
              onChange: e => {
                setOriginalPermitNumber(e.target.value)
              }
            })}
            slotProps={{
              // @ts-expect-error - data-testid is a valid prop, but isn't in the type definition
              input: { ['data-testid']: 'permit-number-input' }
            }}
            label="Permit Number"
            disabled={noNumberGiven}
            error={!!errors.permitNumber}
            helperText={errors.permitNumber?.message}
          />
        </TableCell>
        {permitDoesNotExpire(location, agencyType) ? (
          <TableCell align="left">
            <Typography data-testid="noExpiration">Does not expire</Typography>
          </TableCell>
        ) : (
          <TableCell align="left" sx={{ verticalAlign: 'top' }}>
            <Controller
              name="expirationDate"
              control={control}
              rules={{
                required: !noExpirationGiven ? 'Expiration is required' : false
              }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Expiration"
                  slotProps={{
                    textField: {
                      error: !!errors.expirationDate,
                      helperText: errors.expirationDate?.message
                    }
                  }}
                  data-testid="expiration-date-picker"
                  disabled={noExpirationGiven}
                  onChange={date => {
                    field.onChange(date)
                    updateFormValue('expirationDate', date)
                    setOriginalExpirationDate(date)
                  }}
                />
              )}
            />
          </TableCell>
        )}

        <TableCell align="left">{displayAgencyPermitRequirement(location, agencyType)}</TableCell>
        <TableCell align="left">
          <AppFormLink agencyType={agencyType} location={location} />
        </TableCell>
        <TableCell align="right">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
            <Button variant="outlined" onClick={onCancel} data-testid={'edit-permit-cancel-button'}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={
                createPermitByAgencyTypeMutation.isPending || updatePermitByAgencyTypeMutation.isPending || deletePermitByAgencyTypeMutation.isPending
              }
              onClick={handleSubmit(onSubmit)}
              data-testid={'edit-permit-save-button'}
            >
              {permit ? 'Update' : 'Save'}
            </LoadingButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow className={'selected-indicator'} sx={{ '&>td': { borderBottom: 'none', paddingTop: 0 } }}>
        <TableCell />
        <TableCell />
        <TableCell>
          <Controller
            name="noNumberGiven"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    sx={{ marginLeft: '.5rem' }}
                    checked={field.value}
                    onChange={e => {
                      field.onChange(e.target.checked)
                      updateFormValue('noNumberGiven', e.target.checked)
                      if (e.target.checked) {
                        updateFormValue('permitNumber', '')
                      } else {
                        updateFormValue('permitNumber', originalPermitNumber)
                      }
                    }}
                  />
                }
                label="No number given"
              />
            )}
          />
        </TableCell>
        <TableCell>
          {!permitDoesNotExpire(location, agencyType) && (
            <Controller
              name="noExpirationGiven"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ marginLeft: '.5rem' }}
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={e => {
                        field.onChange(e.target.checked)
                        updateFormValue('noExpirationGiven', e.target.checked)
                        if (e.target.checked) {
                          updateFormValue('expirationDate', null)
                        } else {
                          updateFormValue('expirationDate', originalExpirationDate)
                        }
                      }}
                    />
                  }
                  label="No expiration given"
                />
              )}
            />
          )}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>

      {permit && (
        <TableRow className={'selected-indicator'} data-testid={`${agencyType}-permit-row-delete`}>
          <TableCell colSpan={7}>
            <Box>
              <Controller
                name="permitNoLongerNeeded"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={e => {
                          field.onChange(e.target.checked)
                          updateFormValue('permitNoLongerNeeded', e.target.checked)
                        }}
                      />
                    }
                    label={<Typography data-testid={'delete-checkbox-label'}>This permit is no longer needed</Typography>}
                  />
                )}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export { PermitRowEditor }
