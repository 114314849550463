import { FC } from 'react'
import { Stack, Box } from '@mui/material'
import { Page } from '../../../components/Page'
import { useNavigate, Outlet, useParams } from 'react-router-dom'
import { LocationSearch } from '../../PermitPage/LocationSearch.tsx'

const SearchPage: FC = () => {
  const navigate = useNavigate()
  const { sid } = useParams()

  const onSidChanged = (newSid: string) => {
    navigate(`/address-scheduler/${newSid}/overview`)
  }

  return (
    <Page title={'Address Change Scheduler'} sx={{ padding: 0 }}>
      <Stack direction={'row'} height="100%">
        <Stack flexGrow={1} spacing={'2rem'} useFlexGap p={{ xs: '1rem 2rem', md: '2rem 4rem' }}>
          {!sid && (
            <Box marginTop={'1rem'}>
              <LocationSearch onSidChanged={onSidChanged} />
            </Box>
          )}
          <Outlet />
        </Stack>
      </Stack>
    </Page>
  )
}

export { SearchPage }
