import { Auth0Provider, AuthorizationParams } from '@auth0/auth0-react'
import { FC, useMemo } from 'react'
import { appRouter } from './pages/routes.tsx'
import { RouterProvider } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { getConfigurationService } from './configuration'
import { ALL_SCOPES } from './constants'
import { newQueryClient } from './api/apiUtils.tsx'
import { AuthenticationContextProvider } from './auth/AuthenticationContextProvider.tsx'

import '@simplisafe/blueprint-web-dsl/index.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useSnackbar } from './hooks/useSnackbar.tsx'
import { AppState } from '@auth0/auth0-react/src/auth0-provider.tsx'

/**
 * The main application component. It handles user authentication.
 */
export const App: FC = () => {
  const authorizationParams: AuthorizationParams = {
    redirect_uri: window.location.origin,
    scope: ALL_SCOPES.join(' ')
  }

  const snackbarContext = useSnackbar()

  // we need to make sure that this only gets created once, so putting in a memo
  const queryClient = useMemo(() => newQueryClient(snackbarContext), [snackbarContext])

  return (
    <Auth0Provider
      domain={getConfigurationService().environmentDetails.auth0Domain}
      clientId={getConfigurationService().environmentDetails.auth0ClientId}
      authorizationParams={authorizationParams}
      cacheLocation={'localstorage'}
      onRedirectCallback={(appState?: AppState): void => {
        appRouter.navigate(appState?.returnTo ?? '/', { replace: true })
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AuthenticationContextProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <RouterProvider router={appRouter} fallbackElement={<div>Initializing App...</div>} />
          </LocalizationProvider>
        </AuthenticationContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Auth0Provider>
  )
}
